<template>
    <div class="AboutUs">
        <!-- <h1>关于我们</h1> -->
        <img src="https://img.youstarplanet.com/h5/AboutUs.png" alt="">
        <div class="content">
            <div>服务内容</div>
            <div></div>
            <p>
                深圳市星都时代文化科技有限公司成立于2021年3月，是一家立足文化产业背景，融合信息科技发展，为演艺文化产业及终端消费市场提供优质全链数字服务的平台化公司。
                <br><br>
                线上线下文化展演服务消费、全龄段艺术普及与教育是伴人终身的文化消费场景。星都时代双平台联动完成了B端演艺文化产业的数字产业化和C端多维度、多层级、个性化服务之间的深度融合。用户不仅能够随时随地线上看演出、逛展览、学习全艺术门类各科教程，而且大大扩展了选择维度、降低了时间与金钱的开销成本。双平台的深度融合互通联动不断提供守正创新的优质文化服务产品、保障人民的基本文化权益，创新公共文化服务方式，让人民群众在享受新时代优质文化服务的同时，满足精神需求，增强精神力量，推动文化自信这最深层自信的培育和养成。
                <br><br>
                建设文化强国，助力第二个百年文化强国梦想，应有人认识到这个潜力巨大的赛道里司空见惯难题的根本性解决，在穿透演艺文化产业改革和文化消费场景创新后，最终归根溯源于演艺文化产业由传统产业跨越式迈过工业产业化、产业互联网化、数字文化产业化的三座大山，特别是对供应链、数据链、创新链从产业源头上整体转型升级，从而大大降低全链产品及服务的生产、流通、消费成本，大大提高了流通转化效率，并最终惠及终端用户，能够享受到价廉物美且极为丰富的文化产品与服务。
                <br><br>
                深圳市星都时代文化科技有限公司通过双平台驱动的星动演艺-演艺文化产业数字服务平台和星动视界-文化数字内容服务平台，秉承围绕中心服务大局、聚焦需求技术引领、激活主体整合资源的宗旨，坚持以产业链优化供应链、以数据链增值价值链、以创新链升级内容链，以生态链激活保障链，有效地解决了产品服务去库存、生产流通降成本、商务版权提收入、终端消费新场景的行业诉求与难题。
                <br><br>
                星都时代创始人表示，传统产业转型升级造就了巨大的跨界创新场景和市场机会，随时随地看演出、逛展览、参加艺术普及与培训，在消费者个性化需求及家庭单元化精神文化生活中实现服务内容满足多维度、多层级、多场景需求，而获取服务的方式却更加便利，成本更为实惠，是长期未被满足的刚性需求和技术梦想。星都时代将持续强有力地助推业务规模化快速发展，抓住机遇，顺势而上，致力于成为演艺文化产业服务领域里新一轮产业数字革命的引领者。
            </p>
            <ul>
                <li>
                    <div>办公地址</div>
                    <div>深圳市宝安区西乡街道蚝业社区宝源路1053号资信达大厦518。</div>
                </li>
                <li>
                    <div>联系方式</div>
                    <div>版权合作：18124111200  (张先生)</div>
                    <div>投融资合作：18124110188  (文先生)</div>
                    <div>平台合作：18124111200  (张先生)</div>
                    <div>项目合作：18126208307  (钱先生)</div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default{
    data() {
        return {}
    },
    async mounted() {
        //页面跳转默认顶部
        document.documentElement.scrollTop = 0
    },
}
</script>

<style lang="less">
.AboutUs{
    &>img{
        width: 100%;
        object-fit:cover;
    }
    .content{
        padding: 0px 14px 86px;
        &>div{
            &:nth-child(1){
                margin-top: 38px;
                margin-bottom: 22px;
                font-size: 18px;
                color: #000;
                font-weight: bold;
                text-align: center;
            }
            &:nth-child(2){
                width: 28px;
                height: 3px;
                background-color: #D00A13;
                margin: 0 auto;
                margin-bottom: 24px;
            }
        }
        p{
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 30px;
            padding: 0 13px;
        }
        &>ul{
            margin-top: 36px;
            padding: 21px 18px 27px;
            background-color: #F8F8F8;
            li{
                &:nth-child(1){
                    margin-bottom: 31px;
                }
                div{
                    font-size: 14px;
                    font-weight: 400;
                    color: #848484;
                    line-height: 26px;
                    &:nth-child(1){
                        font-size: 16px;
                        font-weight: bold;
                        color: #000;
                        margin-bottom: 16px;
                    }
                }
            }
        }
    }
}
</style>